import Web3 from "web3";
import { abiEVCNFTStake } from "./ABI/EVCNFTStake"
import { postNotification, getUserMintedTrendNFTAvatars } from "./EVCNFTContractAction"
import { RPCURL, CurrentChainID, ContractAddressTTAVATARSSTAKE, getCurrentAccount, getCurrentSigner,ContractAddressTRNDVEST } from "./ContractDependency.js"
import { ethers } from 'ethers';
import { ethersProvider } from "../config/context.js";
import { BigNumber } from "ethers";
import { abiPool } from "./ABI/Pool";

//dev: HttpProvider
var web3 = new Web3(new Web3.providers.HttpProvider(RPCURL));



//dev: NFT Stake
//Sayyed code
// export const setStakeNFT = async (_id) => {
//     console.log("_idStakeNFT", _id);
//     if (window.ethereum) {
//         const web3 = new Web3(window.ethereum);
//         await window.ethereum.request({ method: "eth_requestAccounts" });
//         //dev: Get the selected account
//         const accounts = await window.ethereum.request({ method: "eth_accounts" });
//         const account = accounts[0];
//         const currentChainId = await web3.eth.net.getId();
//         if (currentChainId !== CurrentChainID) {
//             await web3.currentProvider.request({
//                 method: "wallet_switchEthereumChain",
//                 params: [{ chainId: Web3.utils.toHex(CurrentChainID) }],
//             });
//         }
//         const contracts = new web3.eth.Contract(abiEVCNFTStake, ContractAddressTTAVATARSSTAKE);
//         console.log(contracts);
//         // const planId = 1;
//         // console.log("planId", planId);
//         const id = _id.split(",")
//         console.log("_idStakeNFT", id);
//         const estimatedGasLimit = await contracts.methods
//             .stakeAvatar(id)
//             .estimateGas({ from: account });
//         const gasPrice = await web3.eth.getGasPrice();
//         const nftStake = await contracts.methods.stakeAvatar(id).send({
//             from: account,
//             gas: estimatedGasLimit,
//             gasPrice: gasPrice
//         });
//         console.log("nftStake", nftStake);
//         let messageNotification = `Staked Avatar: ${id}`;
//         await postNotification(account, messageNotification);
//         console.log("nftStakeTXHASH", nftStake.transactionHash);
//         return nftStake.transactionHash;
//     }
// };

//Shreyash code 
export const setStakeNFT = async (_id) => {
  try {
    const signer = await getCurrentSigner();
    const account = await getCurrentAccount();
    const contracts = new ethers.Contract(ContractAddressTTAVATARSSTAKE, abiEVCNFTStake, signer);
    console.log(contracts);
    const arrayid = BigNumber.isBigNumber(_id) ? _id.toString() : _id.toString();
    const id = arrayid.split(",");
    console.log("_idStakeNFT", id);
    const estimatedGasLimit = await contracts.estimateGas.stakeAvatar(id, {
      from: account
    });
    const gasPrice = await ethersProvider.getGasPrice();
    const nftStake = await contracts.stakeAvatar(id, {
      gasLimit: estimatedGasLimit,
      gasPrice: gasPrice
    });
    const stakeNFTReceipt = await nftStake.wait();
    console.log("stakeNFTReceipt", stakeNFTReceipt);
    let messageNotification = `Staked Avatar: ${id}`;
    stakeNFTReceipt.status == 1 ? await postNotification(account, messageNotification) : console.error("nftStakeTXHASH", stakeNFTReceipt?.transactionHash);
    return stakeNFTReceipt?.transactionHash;
  } catch (error) {
    console.error("Error in setStakeNFT:", error);
    return null;
  }
};


//dev: Set Withdraw NFT
//Sayyed code
// export const setWithdrawNFT = async (_id) => {
//     console.log("_idNFTUnStake", _id);
//     if (window.ethereum) {
//         const web3 = new Web3(window.ethereum);
//         await window.ethereum.request({ method: "eth_requestAccounts" });
//         // Get the selected account
//         const accounts = await window.ethereum.request({ method: "eth_accounts" });
//         const account = accounts[0];
//         const currentChainId = await web3.eth.net.getId();
//         if (currentChainId !== CurrentChainID) {
//             await web3.currentProvider.request({
//                 method: "wallet_switchEthereumChain",
//                 params: [{ chainId: Web3.utils.toHex(CurrentChainID) }],
//             });
//         }
//         const contracts = new web3.eth.Contract(abiEVCNFTStake, ContractAddressTTAVATARSSTAKE);
//         console.log(contracts);
//         const id = _id.split(",")
//         console.log("_idWithdrawNFT", id);
//         const estimatedGasLimit = await contracts.methods
//             .unstakeAvatar(id)
//             .estimateGas({ from: account });
//         const gasPrice = await web3.eth.getGasPrice();
//         const nftUnStake = await contracts.methods.unstakeAvatar(id).send({
//             from: account,
//             gas: estimatedGasLimit,
//             gasPrice: gasPrice
//         });
//         console.log("nftUnStake", nftUnStake);
//         let messageNotification = `Unstaked Avatar: ${id}`;
//         await postNotification(account, messageNotification);
//         if (nftUnStake) {
//             // window.location.reload();
//         }
//         console.log("nftUnStake", nftUnStake);
//         console.log("nftUnStakeTXHASH", nftUnStake.transactionHash);
//         return nftUnStake.transactionHash;

//     }
// };

//Shreyash code
export const setWithdrawNFT = async (_id) => {
  try {
    console.log("_idNFTUnStake", _id);
    const signer = await getCurrentSigner();
    const account = await getCurrentAccount();
    const contracts = new ethers.Contract(ContractAddressTTAVATARSSTAKE, abiEVCNFTStake, signer);     
    const arrayid = BigNumber.isBigNumber(_id) ? _id.toString() : _id.toString();
    const id = arrayid.split(",");
    console.log("_idWithdrawNFT", id);    
    const estimatedGasLimit = await contracts.estimateGas.unstakeAvatar(id);
    const gasPrice = await ethersProvider.getGasPrice();    
    const nftUnStake = await contracts.unstakeAvatar(id, {
      gasLimit: estimatedGasLimit,
      gasPrice: gasPrice
    });
    const withdrawNFTReceipt = await nftUnStake.wait();
    console.log("nftUnStake", withdrawNFTReceipt);
    let messageNotification = `Unstaked Avatar: ${id}`;    
    try {
      if (withdrawNFTReceipt.status === 1) {
        await postNotification(account, messageNotification);
      } else {
        console.error("nftUnStakeTXHASH:", withdrawNFTReceipt.transactionHash);
      }
    } catch (notificationError) {
      console.error("Error posting notification:", notificationError);
    }
    console.log("nftUnStakeTXHASH:", withdrawNFTReceipt.transactionHash);
    return withdrawNFTReceipt.transactionHash;
  } catch (error) {
    console.error("Error in setWithdrawNFT:", error);
    return null;
  }
};


//dev: Set Claim Rewards
//Sayyed code
// export const setClaimReward = async (_id) => {
//     console.log("_NFTidClaim", _id);
//     if (window.ethereum) {
//         const web3 = new Web3(window.ethereum);
//         await window.ethereum.request({ method: "eth_requestAccounts" });
//         // Get the selected account
//         const accounts = await window.ethereum.request({ method: "eth_accounts" });
//         const account = accounts[0];
//         const currentChainId = await web3.eth.net.getId();
//         if (currentChainId !== CurrentChainID) {
//             await web3.currentProvider.request({
//                 method: "wallet_switchEthereumChain",
//                 params: [{ chainId: Web3.utils.toHex(CurrentChainID) }],
//             });
//         }
//         const contracts = new web3.eth.Contract(abiEVCNFTStake, ContractAddressTTAVATARSSTAKE);
//         console.log(contracts);
//         const id = _id.split(",");
//         console.log("_NFTidClaim", id);
//         const estimatedGasLimit = await contracts.methods
//             .claimTTReward(id)
//             .estimateGas({ from: account });
//         const gasPrice = await web3.eth.getGasPrice();
//         const idClaimValue = await contracts.methods.claimTTReward(id).send({
//             from: account,
//             gas: estimatedGasLimit,
//             gasPrice: gasPrice
//         });
//         let messageNotification = `Claimed Avatar-Staked Rewards`;
//         await postNotification(account, messageNotification);
//         if (idClaimValue) {
//             // window.location.reload();
//         }
//         console.log("setClaimNFTReward", idClaimValue);
//         return idClaimValue.transactionHash;
//     }
// };

//Shreyash code
export const setClaimReward = async (_id) => {
  try {
    console.log("_NFTidClaim", _id);
    const signer = await getCurrentSigner();
    const account = await getCurrentAccount();
    const contracts = new ethers.Contract(ContractAddressTTAVATARSSTAKE, abiEVCNFTStake, signer);
    console.log(contracts);
    const arrayid = BigNumber.isBigNumber(_id) ? _id.toString() : _id.toString();
    const id = arrayid.split(",");
    console.log("_NFTidClaim", id);
    const estimatedGasLimit = await contracts.estimateGas.claimTTReward(id);
    const gasPrice = await ethersProvider.getGasPrice();
    const idClaimValue = await contracts.claimTTReward(id, {
      gasLimit: estimatedGasLimit,
      gasPrice: gasPrice
    });
    const claimRewardeceipt = await idClaimValue.wait();
    let messageNotification = `Claimed Avatar-Staked Rewards`;
    claimRewardeceipt.status == 1 ? await postNotification(account, messageNotification) : console.error("setClaimNFTReward", claimRewardeceipt.transactionHash);;
    console.log("claimRewardeceipt", claimRewardeceipt);
    return claimRewardeceipt;
  } catch (error) {
    console.error("Error in setClaimReward:", error);
    return null;
  }
};


//dev: Set Repurchase NFT
//sayyed code
// export const setRepurchaseNFT = async (_id) => {
//     console.log("_idRepurchaseNFT", _id);
//     if (window.ethereum) {
//         const web3 = new Web3(window.ethereum);
//         await window.ethereum.request({ method: "eth_requestAccounts" });
//         //dev: Get the selected account
//         const accounts = await window.ethereum.request({ method: "eth_accounts" });
//         const account = accounts[0];
//         const currentChainId = await web3.eth.net.getId();
//         if (currentChainId !== CurrentChainID) {
//             await web3.currentProvider.request({
//                 method: "wallet_switchEthereumChain",
//                 params: [{ chainId: Web3.utils.toHex(CurrentChainID) }],
//             });
//         }
//         const contracts = new web3.eth.Contract(abiEVCNFTStake, ContractAddressTTAVATARSSTAKE);
//         console.log(contracts);
//         // const id = _id.split(",")
//         // console.log("_idRepurchaseNFT", id);
//         const estimatedGasLimit = await contracts.methods
//             .repurchase(account, _id)
//             .estimateGas({ from: account });
//         const gasPrice = await web3.eth.getGasPrice();
//         const repurchaseNFT = await contracts.methods.repurchase(account, _id).send({
//             from: account,
//             gas: estimatedGasLimit,
//             gasPrice: gasPrice
//         });
//         console.log("RepurchaseNFT", repurchaseNFT);
//         let messageNotification = `Repurchased Avatar: ${_id}`;
//         await postNotification(account, messageNotification);
//     }
// };

//Shreyash code 
export const setRepurchaseNFT = async (_id) => {
  try {
    console.log("_idRepurchaseNFT", _id);
    const signer = await getCurrentSigner();
    const account = await getCurrentAccount();
    const contracts = new ethers.Contract(ContractAddressTTAVATARSSTAKE, abiEVCNFTStake, signer);
    console.log(contracts);
    const estimatedGasLimit = await contracts.estimateGas.repurchase(account, _id);
    const gasPrice = await ethersProvider.getGasPrice();
    const repurchaseNFT = await contracts.repurchase(account, _id, {
      gasLimit: estimatedGasLimit,
      gasPrice: gasPrice
    });
    const repurchaseNFTReceipt = await repurchaseNFT.wait();
    console.log("RepurchaseNFT", repurchaseNFTReceipt);
    let messageNotification = `Repurchased Avatar: ${_id}`;
    await postNotification(account, messageNotification);
  } catch (error) {
    console.error("Error in setRepurchaseNFT:", error);
    return null;
  }
};


//Sayyed Code
// export const getRepurchaseLimit = async (_id) => {
//   console.log("getRepurchaseLimit");
//   let contracts = new web3.eth.Contract(abiEVCNFTStake, ContractAddressTTAVATARSSTAKE);
//   const repurchaseLimitWeiValue = await contracts.methods.tokenIdRepurchaseLimit(_id).call();
//   // const repurchaseLimitEthValue = web3.utils.fromWei(repurchaseLimitWeiValue, "ether");
//   console.log("repurchaseLimitEthValue", repurchaseLimitWeiValue);
//   return repurchaseLimitWeiValue;
// };

//Shreyash Code
export const getRepurchaseLimit = async (_id) => {
  console.log("Fetching repurchase limit for token ID:", _id);
  const contract = new ethers.Contract(ContractAddressTTAVATARSSTAKE, abiEVCNFTStake, ethersProvider);
  try {
    const repurchaseLimitWeiValue = await contract.tokenIdRepurchaseLimit(_id);
    // Convert from Wei to Ether (if needed)
    // const repurchaseLimitEthValue = ethers.utils.formatEther(repurchaseLimitWeiValue);
    console.log("repurchaseLimitWeiValue:", repurchaseLimitWeiValue);
    return repurchaseLimitWeiValue; // Return the value in Wei
  } catch (error) {
    console.error("Error fetching repurchase limit:", error);
    return null; // Return null or handle the error appropriately
  }
};

//Sayyed Code
//dev: Get Tokens of Staker
// export const getTokensOfStaker = async () => {
//   console.log("getTokensOfStaker");
//   let contracts = new web3.eth.Contract(abiEVCNFTStake, ContractAddressTTAVATARSSTAKE);
//   const accounts = await window.ethereum.request({
//     method: "eth_accounts",
//   });
//   const account = accounts[0];
//   console.log("gettokensOfStakerNFTIDs_account", account);
//   const tokensOfStakerNFTIDs = await contracts.methods.getAvatarsOfStaker(account).call();
//   console.log("tokensOfStakerNFTIDs", tokensOfStakerNFTIDs);
//   return tokensOfStakerNFTIDs;
// };
//Shreyash Code
export const getTokensOfStaker = async () => {
  console.log("getTokensOfStaker");
  if (!ethersProvider) {
    console.error("ethersProvider is not available");
    return;
  }
  const signer = await ethersProvider.getSigner();
  const account = await signer.getAddress();
  console.log("getTokensOfStaker_account", account);
  const contracts = new ethers.Contract(ContractAddressTTAVATARSSTAKE, abiEVCNFTStake, signer);
  try {
    const tokensOfStakerNFTIDs = await contracts.getAvatarsOfStaker(account);
    console.log("Tokens of Staker NFT IDs", tokensOfStakerNFTIDs);
    return tokensOfStakerNFTIDs;
  } catch (error) {
    console.error("Error fetching tokens of staker:", error);
    return [];
  }
};

//Sayyed Code
// export const getRewardPercentage = async (_id) => {
//   console.log("getRewardPercentage");
//   let contracts = new web3.eth.Contract(abiEVCNFTStake, ContractAddressTTAVATARSSTAKE);
//   const accounts = await window.ethereum.request({
//     method: "eth_accounts",
//   });
//   const account = accounts[0];
//   console.log("getRewardPercentage_account", account);
//   const rewardPercentage = await contracts.methods.getTTRewardPercentage(account, _id).call();
//   console.log("rewardPercentage", rewardPercentage);
//   return rewardPercentage;
// };
//Shreyash Code
export const getRewardPercentage = async (_id) => {
  console.log("Fetching reward percentage");
  if (!ethersProvider) {
    console.error("ethersProvider is not available");
    return;
  }
  const signer = await getCurrentSigner();
  const account = await getCurrentAccount();
  console.log("getRewardPercentage_account:", account);
  const contract = new ethers.Contract(ContractAddressTTAVATARSSTAKE, abiEVCNFTStake, signer);
  try {
    const rewardPercentage = await contract.getTTRewardPercentage(account, _id);
    console.log("Reward Percentage:", rewardPercentage);
    return rewardPercentage;
  } catch (error) {
    console.error("Error fetching reward percentage:", error);
    return null; // Handle the error as needed
  }
};


//dev: Get Unclaimable Reward
//Sayyed Code
// export const getUnClaimableReward = async (_id) => {
//   console.log("_NFTidClaim", _id);
//   const contracts = new web3.eth.Contract(abiEVCNFTStake, ContractAddressTTAVATARSSTAKE);
//   console.log(contracts);
//   // const id = _id.split(",")
//   // console.log("_NFTidClaim", id);
//   const idUnClaimValue = await contracts.methods.getUnclaimedTTReward(_id).call();
//   console.log("getUnClaimedReward", idUnClaimValue);
//   let claimableNFTreward = web3.utils.fromWei(idUnClaimValue, "ether");
//   console.log("claimableNFTreward", claimableNFTreward);
//   let UnClaimedReward = parseFloat(claimableNFTreward).toFixed(6);
//   console.log("UnClaimedReward", UnClaimedReward);
//   return UnClaimedReward;
// };
//Shreyash Code
export const getUnClaimableReward = async (id) => {
  try {
    const arrayid = BigNumber.isBigNumber(id) ? id.toString() : id.toString();
    console.log("arrayid", arrayid);
    if (!ethersProvider) {
      console.error("ethersProvider is not available");
      return;
    }
    const signer = await getCurrentSigner();
    const contract = new ethers.Contract(ContractAddressTTAVATARSSTAKE, abiEVCNFTStake, signer);
    const idUnClaimValue = await contract.getUnclaimedTTReward(arrayid);
    console.log("getUnClaimedReward", idUnClaimValue);
    const claimableNFTreward = ethers.utils.formatEther(idUnClaimValue);
    console.log("claimableNFTreward", claimableNFTreward);
    const UnClaimedReward = parseFloat(claimableNFTreward).toFixed(6);
    console.log("UnClaimedReward", UnClaimedReward);
    return UnClaimedReward;
  } catch (error) {
    console.error("Error in getUnClaimableReward:", error);
  }
};

// getUnClaimableReward(90001)
//Sayyed Code
// export const getEVCToBUSDAmountsOut = async (_id) => {
//   console.log("_NFTidClaim", _id);
//   const contracts = new web3.eth.Contract(abiEVCNFTStake, ContractAddressTTAVATARSSTAKE);
//   console.log(contracts);
//   // const id = _id.split(",")
//   // console.log("_NFTidClaim", id);
//   let claimedEvc = await getRepurchaseLimit(_id)
//   if (claimedEvc > 0) {
//     const EVCToBUSDAmountsOutwei = await contracts.methods.getAmountoutTTToUsdc(claimedEvc).call();
//     console.log("getUnClaimedReward", EVCToBUSDAmountsOutwei);
//     let EVCToBUSDAmountsOut = web3.utils.fromWei(EVCToBUSDAmountsOutwei, "ether");
//     let EVCtoBUSDAmountsOut = Number(EVCToBUSDAmountsOut).toFixed(4);
//     console.log("EVCtoBUSDAmountsOut", EVCtoBUSDAmountsOut);
//     return EVCtoBUSDAmountsOut;
//   }
//   return 0;
// };
//Shreyash Code
export const getEVCToBUSDAmountsOut = async (_id) => {
  console.log("_NFTidClaim:", _id);
  if (!ethersProvider) {
    console.error("ethersProvider is not available");
    return;
  }
  const signer = await getCurrentSigner();
  const contracts = new ethers.Contract(ContractAddressTTAVATARSSTAKE, abiEVCNFTStake, signer);
  console.log(contracts);
  try {
    const claimedEvc = await getRepurchaseLimit(_id);
    if (claimedEvc > 0) {
      const EVCToBUSDAmountsOutwei = await contracts.getAmountoutTTToUsdc(claimedEvc);
      console.log("EVCToBUSDAmountsOutwei:", EVCToBUSDAmountsOutwei);
      const EVCToBUSDAmountsOut = ethers.utils.formatEther(EVCToBUSDAmountsOutwei);
      const formattedAmount = Number(EVCToBUSDAmountsOut).toFixed(4);
      console.log("EVCToBUSDAmountsOut:", formattedAmount);
      return formattedAmount;
    }
    return 0;
  } catch (error) {
    console.error("Error fetching EVC to BUSD amounts out:", error);
    return 0; // Return a default value on error
  }
};

//Sayyed Code
// export const getamountsoutEVCToBUSD = async (_amountIn) => {
//   console.log("_amountIn", _amountIn);
//   const contracts = new web3.eth.Contract(abiEVCNFTStake, ContractAddressTTAVATARSSTAKE);
//   console.log(contracts);
//   if (_amountIn > 0) {
//     let _amountInWei = web3.utils.toWei(_amountIn, "ether");
//     const EVCToBUSDAmountsOutwei = await contracts.methods.getAmountoutTTToUsdc(_amountInWei).call();
//     console.log("EVCToBUSDAmountsOutwei", EVCToBUSDAmountsOutwei);
//     // let EVCToBUSDAmountsOut = web3.utils.fromWei(EVCToBUSDAmountsOutwei, "ether");
//     let EVCToBUSDAmountsOut = Number(EVCToBUSDAmountsOutwei) / 10 ** 6;
//     let EVCtoBUSDAmountsOut = Number(EVCToBUSDAmountsOut).toFixed(4);
//     console.log("EVCtoBUSDAmountsOut1", EVCtoBUSDAmountsOut);
//     return EVCtoBUSDAmountsOut;
//   }
//   return 0;
// };

//Shreyash Code
export const getamountsoutEVCToBUSD = async (_amountIn) => {
  console.log("_amountIn:", _amountIn);
  if (!ethersProvider) {
    console.error("ethersProvider is not available");
    return;
  }
  const signer = await getCurrentSigner();
  const contracts = new ethers.Contract(ContractAddressTTAVATARSSTAKE, abiEVCNFTStake, signer);
  console.log(contracts);
  if (_amountIn > 0) {
    const _amountInWei = ethers.utils.parseEther(_amountIn.toString());
    try {
      const EVCToBUSDAmountsOutwei = await contracts.getAmountoutTTToUsdc(_amountInWei);
      console.log("EVCToBUSDAmountsOutwei:", EVCToBUSDAmountsOutwei);
            const EVCToBUSDAmountsOut = Number(EVCToBUSDAmountsOutwei) / 10 ** 6;
      const formattedAmount = Number(EVCToBUSDAmountsOut).toFixed(4);
      console.log("EVCtoBUSDAmountsOut:", formattedAmount);
      return formattedAmount;
    } catch (error) {
      console.error("Error fetching EVC to BUSD amounts out:", error);
      return 0; // Return a default value on error
    }
  }
  return 0;
};

//Sayyed COde
// export const getCheckRepurchase = async (_id) => {
//   console.log("getCheckRepurchaseId", _id);
//   const contracts = new web3.eth.Contract(abiEVCNFTStake, ContractAddressTTAVATARSSTAKE);
//   console.log(contracts);
//   const accounts = await window.ethereum.request({
//     method: "eth_accounts",
//   });
//   const account = accounts[0];
//   const CheckRepurchase = await contracts.methods.checkRepurchase(account, _id).call();
//   console.log("CheckRepurchase", CheckRepurchase);
//   return CheckRepurchase;
// };

//Shreyash Code
export const getCheckRepurchase = async (_id) => {
  console.log("getCheckRepurchaseId:", _id);
  if (!ethersProvider) {
    console.error("ethersProvider is not available");
    return;
  }
  const signer =await getCurrentSigner();
  const contracts = new ethers.Contract(ContractAddressTTAVATARSSTAKE, abiEVCNFTStake, signer);
  console.log(contracts);
  try {
    const account = await getCurrentAccount();
    console.log("Account:", account);
    const checkRepurchase = await contracts.checkRepurchase(account, _id);
    console.log("CheckRepurchase:", checkRepurchase);
    return checkRepurchase;
  } catch (error) {
    console.error("Error in getCheckRepurchase:", error);
    return null; // Return null or handle the error as needed
  }
};

//Sayyed Code
// export const getIsToBeCheckRepurchase = async () => {
//   console.log("getIsToBeCheckRepurchase");
//   const contracts = new web3.eth.Contract(abiEVCNFTStake, ContractAddressTTAVATARSSTAKE);
//   console.log(contracts);
//   const accounts = await window.ethereum.request({
//     method: "eth_accounts",
//   });
//   const account = accounts[0];
//   const userTokenId = await getUserMintedTrendNFTAvatars(account);
//   console.log("userTokenId", userTokenId);
//   const tokenIdArray = [1, 600001, 900001, 1100001, 1200001, 1250001, 1270001, 1280001];
//   const expectedCheckRepurchase = [false, false, false, false, false, false, false, false];
//   const checkRepurchaseResults = expectedCheckRepurchase.map(() => false);
//   if (userTokenId.length > 0) {
//     for (let i = 0; i < userTokenId.length; i++) {
//       const _id = userTokenId[i];
//       const index = tokenIdArray.indexOf(_id);
//       if (index !== -1) {
//         checkRepurchaseResults[index] = true;
//       }
//     }
//   }
//   console.log("checkRepurchaseResults", checkRepurchaseResults);
//   return checkRepurchaseResults;
// };
//Shreyash Code
export const getIsToBeCheckRepurchase = async () => {
  console.log("getIsToBeCheckRepurchase");
  if (!ethersProvider) {
    console.error("ethersProvider is not available");
    return;
  }
  const signer = await getCurrentSigner();
  const contracts = new ethers.Contract(ContractAddressTTAVATARSSTAKE, abiEVCNFTStake, signer);
  console.log(contracts);
  try {
    const account = await getCurrentAccount();
    const userTokenId = await getUserMintedTrendNFTAvatars(account);
    console.log("userTokenId:", userTokenId);
    const tokenIdArray = [1, 600001, 900001, 1100001, 1200001, 1250001, 1270001, 1280001];
    const expectedCheckRepurchase = [false, false, false, false, false, false, false, false];
    const checkRepurchaseResults = expectedCheckRepurchase.map(() => false);
    if (userTokenId.length > 0) {
      for (let _id of userTokenId) {
        const index = tokenIdArray.indexOf(_id);
        if (index !== -1) {
          checkRepurchaseResults[index] = true;
        }
      }
    }
    console.log("checkRepurchaseResults:", checkRepurchaseResults);
    return checkRepurchaseResults;
  } catch (error) {
    console.error("Error in getIsToBeCheckRepurchase:", error);
    return [];
  }
};

getIsToBeCheckRepurchase()

//Sayyed Code
// export const getTokenInfos = async (_id) => {
//   console.log("getTokenInfos", _id);
//   const contracts = new web3.eth.Contract(abiEVCNFTStake, ContractAddressTTAVATARSSTAKE);
//   console.log(contracts);
//   const tokenInfo = await contracts.methods.getTrendTokenInfo(_id).call();
//   console.log("tokenInfo", tokenInfo);
//   return tokenInfo;
// };

//Shreyash Code
export const getTokenInfos = async (_id) => {
  try {
      if (!ethersProvider) {
          console.error("ethersProvider is not available");
          return;
      }
      // const arrayid = BigNumber.isBigNumber(_id) ? _id.toString() : _id.toString();
      const signer = await getCurrentSigner();
      const account = await getCurrentAccount();
      const contract = new ethers.Contract(ContractAddressTTAVATARSSTAKE, abiEVCNFTStake, signer);
      const contractVest =  new ethers.Contract(ContractAddressTRNDVEST, abiPool, signer);
      // const [startTime, endTime, totalClaimed] = await contract.getTrendTokenInfo(arrayid);
      // const tokenInfos =  [
      //     startTime.toString(),
      //     endTime.toString(),
      //     totalClaimed.toString(),
      // ];
      // console.log("getTokenInfos", tokenInfos);
      // return tokenInfos;
      const stakeRewardAmount = await contract.getUserTTClaimedAvatarStake(account);
      console.log("stakeRewardAmount", stakeRewardAmount.toString());
      const RankrewardAmount = await contractVest.getUserTTRedeemedAmount(account);
      console.log("RankrewardAmount", RankrewardAmount.toString());  
      // Use BigNumber's addition method
      const totalClaimed = stakeRewardAmount.add(RankrewardAmount);
      console.log("totalClaimed", totalClaimed.toString());
      
      return totalClaimed;
      

  } catch (error) {
      console.error("Error_getTokenInfos:", error);
  }
};


//dev: get Next ClaimTime
//Sayyed Code
// export const getNextClaimingTime = async (_id) => {
//   console.log("_NFTidNextClaim", _id);
//   const contracts = new web3.eth.Contract(abiEVCNFTStake, ContractAddressTTAVATARSSTAKE);
//   console.log(contracts);
//   const accounts = await window.ethereum.request({
//     method: "eth_accounts",
//   });
//   const account = accounts[0];
//   console.log("getNFTNextClaim_account", account);
//   // const id =( _id.split(",")).toString();
//   // console.log("_NFTidNextClaim", id);
//   const nextClaim = await contracts.methods.getNextClaimTime(account, _id).call();
//   console.log("NextClaim111", nextClaim);
//   const ClaimTime = window.localStorage.setItem("nextClaim ", nextClaim);
//   console.log("ClaimTime", ClaimTime)
//   return nextClaim;
// };

//Shreyash Code
export const getNextClaimingTime = async (_id) => {
  console.log("_NFTidNextClaim", _id);
  if (!ethersProvider) {
    console.error("ethersProvider is not available");
    return;
  }
  const signer = await getCurrentSigner();
  const contracts = new ethers.Contract(ContractAddressTTAVATARSSTAKE, abiEVCNFTStake, signer);
  console.log(contracts);
  try {
    const accounts = await getCurrentAccount();
    console.log("getNFTNextClaim_account", accounts);
    const nextClaim = await contracts.getNextClaimTime(accounts, _id);
    console.log("NextClaim", nextClaim);
    window.localStorage.setItem("nextClaim", nextClaim.toString());
    console.log("ClaimTime stored in localStorage");
    return nextClaim;
  } catch (error) {
    console.error("Error fetching next claiming time:", error);
    return null; // Or handle the error as needed
  }
};

//dev: get Current APR Of Plan
//Sayyed Code
// export const getCurrentAPROfNFTLevel = async (tokenId) => {
//   const contracts = new web3.eth.Contract(abiEVCNFTStake, ContractAddressTTAVATARSSTAKE);
//   console.log("APRForNftLevel", tokenId);
//   const CurrentAPR = await contracts.methods.getCurrentAPRForAvatarId(tokenId).call();
//   console.log("CurrentAPR", CurrentAPR);
//   // const CurrentAprPerc = CurrentAPR;
//   // console.log("CurrentAprPerc", CurrentAprPerc);
//   return CurrentAPR;
// };
//Shreyash Code
export const getCurrentAPROfNFTLevel = async (_id) => {
  try {
    if (!ethersProvider) {
      console.error("ethersProvider is not available");
      return;
    }
    const arrayid = BigNumber.isBigNumber(_id) ? _id.toString() : _id.toString();
    const signer = await getCurrentSigner();
    const contract = new ethers.Contract(ContractAddressTTAVATARSSTAKE, abiEVCNFTStake, signer);
    const currentApr = await contract.getCurrentAPRForAvatarId(arrayid);
    const currentAPR = BigNumber.isBigNumber(currentApr) ? currentApr.toString() : currentApr.toString();
    console.log("CurrentAPR", currentAPR);
    return currentAPR;
  } catch (error) {
    console.error("Error in getCurrentAPROfNFTLevel:", error);
  }
};






//dev: get Stake Balances NFTs
// defined but never used nor such view function in contract.
//Sayyed Code
// export const getStakeBalancesNFTs = async () => {
//   console.log("getStakeBalancesNFTs");
//   let contracts = new web3.eth.Contract(abiEVCNFTStake, ContractAddressTTAVATARSSTAKE);
//   const accounts = await window.ethereum.request({
//     method: "eth_accounts",
//   });
//   const account = accounts[0];
//   console.log("getStakeBalancesNFTs_account", account);
//   const stakeBalancesNFTs = await contracts.methods.stakeBalances(account).call();
//   console.log("stakeBalancesNFTs", stakeBalancesNFTs);
//   return stakeBalancesNFTs;
// };

//Shreyash Code
export const getStakeBalancesNFTs = async () => {
  console.log("getStakeBalancesNFTs");
  if (!ethersProvider) {
    console.error("ethersProvider is not available");
    return;
  }
  const signer = await getCurrentSigner();
  const contracts = new ethers.Contract(ContractAddressTTAVATARSSTAKE, abiEVCNFTStake, signer);
  const accounts = await getCurrentAccount();
  console.log("getStakeBalancesNFTs_account", accounts);
  try {
    const stakeBalancesNFTs = await contracts.stakeBalances(accounts);
    console.log("stakeBalancesNFTs", stakeBalancesNFTs);
    return stakeBalancesNFTs;
  } catch (error) {
    console.error("Error fetching stake balances:", error);
    return null; // Or handle the error as needed
  }
};


//dev: get Stake Balances NFTs
//Sayyed Code
// defined but never used nor such view function in contract.
// export const getFinalWithdraw = async (_id) => {
//   console.log("_NFTidFinalWithdraw", _id);
//   const contracts = new web3.eth.Contract(abiEVCNFTStake, ContractAddressTTAVATARSSTAKE);
//   console.log(contracts);
//   const accounts = await window.ethereum.request({
//     method: "eth_accounts",
//   });
//   const account = accounts[0];
//   console.log("getNFTFinalWithdraw_account", account);
//   const id = _id.split(",")
//   console.log("_NFTidFinalWithdraw", id);
//   const nextClaim = await contracts.methods.final_ID_withdraw(account, id).call();
//   console.log("getFinalWithdraw", nextClaim);
//   return nextClaim;
// };
//Shreyash Code
export const getFinalWithdraw = async (_id) => {
  console.log("_NFTidFinalWithdraw", _id);
  if (!ethersProvider) {
    console.error("ethersProvider is not available");
    return;
  }
  const signer = await getCurrentSigner();
  const contracts = new ethers.Contract(ContractAddressTTAVATARSSTAKE, abiEVCNFTStake, signer);
  const account = await getCurrentAccount();
  console.log("getNFTFinalWithdraw_account", account);
  const idArray = _id.split(",");
  console.log("_NFTidFinalWithdraw", idArray);
  try {
    const nextClaim = await contracts.final_ID_withdraw(account, idArray);
    console.log("getFinalWithdraw", nextClaim);
    return nextClaim;
  } catch (error) {
    console.error("Error fetching final withdraw:", error);
    return null; // Or handle the error as needed
  }
};
