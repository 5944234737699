import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { ContractAddressTRNDToken } from "../../ContractAction/ContractDependency";
import "react-toastify/dist/ReactToastify.css";
import { getMyReferrer } from "../../ContractAction/EVCNFTContractAction";
import { ReactComponent as IconCopy } from "../../assets/img/dashboard/icons/copy-icon.svg";
import RihgtImg from '../../assets/img/dashboard/img/Sponsor.png';
import CopyIcon from '../../assets/img/dashboard/img/copy-icon.svg';
import {
  APIUSEREFERRAL,
  APIDASHBOARDREF,
  AUTHUSERNAME,
  AUTHUSERPASSWORD,
} from "../../ContractAction/ContractDependency";
import { useTranslation } from "react-i18next";

//dev: Postthe  data for API
const postData = () => {
  const affiliateaddress = window.localStorage.getItem("connectedAccount");
  const sponsoraddress = window.localStorage.getItem("sponsorAddress");
  const data = {
    affiliateaddress: affiliateaddress,
    sponsoraddress: sponsoraddress,
  };

  //dev: Fetch the API Data
  fetch(`${APIUSEREFERRAL}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Basic " + btoa(`${AUTHUSERNAME}:${AUTHUSERPASSWORD}`),
    },
    body: JSON.stringify(data),
  })
    .then((result) => {
      return result.json();
    })
    .catch((err) => console.log(err));
};

const ReferralLink = ({ title }) => {
  const [myreferrer, setMyReferrer] = useState();
  useEffect(() => {
    const getData = async () => {
      let referrerInfo = await getMyReferrer();
      setMyReferrer(referrerInfo);
    };
    getData();
  }, []);

  const text = `${APIDASHBOARDREF}`;

  const affiliateaddress = window.localStorage.getItem("connectedAccount");
  const sponsoraddress = window.localStorage.getItem("sponsorAddress") || "0x0000000000000000000000000000000000000000";
  const userAddress = window.localStorage.getItem("connectedAccount");
  postData();
  //dev: Copy text
  const handleCopy = (text) => {
    navigator.clipboard.writeText(`${text}${affiliateaddress}`);
    toast.success("Successfully Copied the Referral Address!", { autoClose: 1000 });
  };
  const handleCopySponsoraddress = (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Copied!", { autoClose: 1000 });
  };

  useEffect(() => {
    document.title = title ? title : "TT Avatars | Referral Link";
    document.querySelector(".page-title").innerText = "Referral Link";
  }, []);

  const { t } = useTranslation();
  return (
    <div className="dashboard-wrap">

      <div className="row justify-contnet-center">
        <div className="col-12">

          <div className="dash-content-area-shree">
            <div class="dash-token-wrap d-flex justify-content-between g-4 flex-wrap flex-md-nowrap">
              <div className="dash-content-wrap-inner w-100">
                <p class="evc-title copy-title">
                  {t("Sponsor Address")} :{" "}
                </p>
                <div className="sponsor-token d-inline-flex align-items-center" style={{maxWidth:'425px',width:"100%"}}>
                  <div className="token-address" >
                  {myreferrer !== "0x0000000000000000000000000000000000000000"
                  ? myreferrer
                  : sponsoraddress !== userAddress
                    ? sponsoraddress
                    : null}
                    {/* NOTE: update the address with latest one */}                    {/* NOTE: update the address with latest one */}
                  </div>
                  <div className="cursor-pointer">
                    <IconCopy  onClick={() => {
                        const addressToCopy = myreferrer && myreferrer !== "0x0000000000000000000000000000000000000000"
                          ? myreferrer
                          : sponsoraddress;
                
                        if (addressToCopy && addressToCopy !== "0x0000000000000000000000000000000000000000") {
                          handleCopySponsoraddress(addressToCopy);
                        } else {
                          console.log("No valid address to copy.");
                        }
                      }} />
                  </div>
                </div>
                {/* <div>
                  <Link class="stake-txt">
                    {myreferrer != "0x0000000000000000000000000000000000000000"
                      ? myreferrer
                      : sponsoraddress}
                  </Link>
                </div> */}

                {/*dev:Show the  Reference Address */}
                <div className="pt-4">
                  <p class="evc-title copy-title">
                    {" "}
                    {t("Referral Address")}:{" "}
                  </p>
                  <div className="copy-link">
                    <Link class="stake-txt">
                      {APIDASHBOARDREF}
                      {affiliateaddress}
                    </Link>
                  </div>
                  <div
                    style={{ display: "inline-block" }}
                    onClick={() => handleCopy(text)}
                  >
                    <button className="btn btn-primary-bg-custom copy-button">
                      {
                        <img src={CopyIcon} alt="" style={{ marginRight: "8px" }} />
                      }
                      {t("Copy")}
                    </button>

                  </div>
                </div>
              </div>
              <div className="dash-right-img">
                <img src={RihgtImg} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="greadient-border"></div>
    </div>
  );
};

export default ReferralLink;
