import Web3 from "web3";
import React, { useState, useEffect } from "react";
import level1 from "../Mint/Images/level1.png";
import level2 from "../Mint/Images/level2.png";
import level3 from "../Mint/Images/level3.png";
import level4 from "../Mint/Images/level4.png";
import level5 from "../Mint/Images/level5.png";
import level6 from "../Mint/Images/level6.png";
import level7 from "../Mint/Images/level7.png";
import level8 from "../Mint/Images/level8.png";
import {
  getUserAvatarRepurchaseDetails,
  setRepurchaseNFTTrendAvatar,
} from "../../../ContractAction/EVCNFTContractAction";
import busd from "../../../assets/img/dashboard/icons/tticon.svg";

import { abiBUSD } from "../../../ContractAction/ABI/BUSD";
import {allowanceBUSD, setBUSD_NFTApprove } from "../../../ContractAction/BUSDContractAction";
import {
  ContractAddressUSDC,
  ContractAddressTTAVATARS, BaseExplorerUrl
} from "../../../ContractAction/ContractDependency";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";

function Repurchase({ setUpdated, updated }) {
  const [repurchaseData, setRepurchaseData] = useState([]);
  const [avatarTokenId, setAvatarTokenId] = useState();
  const [isApproved, setIsApproved] = useState([]);

  const [approveBUSDValue, setApproveBUSDValue] = useState();
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width:991px)");

  useEffect(() => {
    const getAllowanceBUSD = async () => {
      try {
        const allowanceBusdToNftAvatars = await allowanceBUSD(
          ContractAddressTTAVATARS
        )
          setApproveBUSDValue(allowanceBusdToNftAvatars);        
      } catch (error) {
        return error;
      }
    };
    getAllowanceBUSD();
  }, [updated]);

  const repurchaseDataHandler = async () => {
    try {
      const repuresponse = await getUserAvatarRepurchaseDetails();
      setRepurchaseData(repuresponse);
      setAvatarTokenId(
        repuresponse?.map((item) => {
          return item?.avatarTokenId;
        })
      );
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    repurchaseDataHandler();
  }, [updated]);


  const avatarBuyPriceRanges = [
    { min: 1, max: 600000, price: 250 },
    { min: 600001, max: 900000, price: 1250 },
    { min: 900001, max: 1100000, price: 2500 },
    { min: 1100001, max: 1200000, price: 6250 },
    { min: 1200001, max: 1250000, price: 15000 },
    { min: 1250001, max: 1270000, price: 30000 },
    { min: 1270001, max: 1280000, price: 75000 },
    { min: 1280001, max: 1285000, price: 150000 },
  ];
  const getImgAsset = (item) => {
    if (item >= 1 && item <= 600000) {
      return level1;
    } else if (item >= 600001 && item <= 900000) {
      return level2;
    } else if (item >= 900001 && item <= 1100000) {
      return level3;
    } else if (item >= 1100001 && item <= 1200000) {
      return level4;
    } else if (item >= 1200001 && item <= 1250000) {
      return level5;
    } else if (item >= 1250001 && item <= 1270000) {
      return level6;
    } else if (item >= 1270001 && item <= 1280000) {
      return level7;
    } else if (item >= 1280001 && item <= 1285000) {
      return level8;
    } else {
      return null;
    }
  };

  const evc_avatars = [
    {
      title: "Crypto Newbies",
      APRUpto: "APR: Up to 84%",
      evc_no: "#EVC 1",
      price: "$110",
      bv: "$100",
    },
    {
      title: "Crypto Enthusiast",
      APRUpto: "APR: Up to 108%",
      evc_no: "#EVC 2",
      price: "$550",
      bv: "$500",
    },
    {
      title: "Crypto Entrepreneur",
      APRUpto: "APR: Up to 132%",
      evc_no: "#EVC 3",
      price: "$1,100",
      bv: "$1,000",
    },
    {
      title: "Crypto Investor",
      APRUpto: "APR: Up to 156%",
      evc_no: "#EVC 4",
      price: "$2,750",
      bv: "$2,5OO",
    },
    {
      title: "Crypto King",
      APRUpto: "APR: Up to 180%",
      evc_no: "#EVC 5",
      price: "$5,500",
      bv: "$5,000",
    },
    {
      title: "Blockchain Mogul",
      APRUpto: "APR: Up to 204%",
      evc_no: "#EVC 6",
      price: "$11,000",
      bv: "$10,000",
    },
    {
      title: "Bitcoin Billionaire",
      APRUpto: "APR: Up to 228%",
      evc_no: "#EVC 7",
      price: "$27,500",
      bv: "$25,000",
    },
    {
      title: "CryptoCap Tycoon",
      APRUpto: "APR: Up to 252%",
      evc_no: "#EVC 8",
      price: "$55,000",
      bv: "$50,000",
    },
  ];
  const getPricenVolume = (data1) => {
    if (data1 >= 1 && data1 <= 600000) {
      return 0;
    } else if (data1 >= 600001 && data1 <= 900000) {
      return 1;
    } else if (data1 >= 900001 && data1 <= 1100000) {
      return 2;
    } else if (data1 >= 1100001 && data1 <= 1200000) {
      return 3;
    } else if (data1 >= 1200001 && data1 <= 1250000) {
      return 4;
    } else if (data1 >= 1250001 && data1 <= 1270000) {
      return 5;
    } else if (data1 >= 1270001 && data1 <= 1280000) {
      return 6;
    } else if (data1 >= 1280001 && data1 <= 1285000) {
      return 7;
    } else {
      // Add a default value or handle other cases as needed
      return null;
    }
  };
  const handleApprove = async () => {
    const txHash = await setBUSD_NFTApprove();
    console.log("txhashRepurchase", txHash);
    setUpdated(txHash?.transactionHash);
    if (txHash?.transactionHash) {
      handleTxhashShow(txHash?.transactionHash);
    } else if (txHash?.transactionHash === undefined) {
      toast.error("Transaction Cancelled");
    }
  };
  const handleRepurchase = async (id) => {
    const response = await setRepurchaseNFTTrendAvatar(id);
    setUpdated(response?.transactionHash);
    const txHash = response?.transactionHash;
    if (txHash) {
      handleTxhashShow(txHash);
    } else if (txHash === undefined) {
      toast.error("Transaction Cancelled");
    }
  };
  const handleTxhashShow = (txHash) => {
    toast.success(
      <div>
        Transaction Receipt: <br />
        <a
          href={`${BaseExplorerUrl}tx/${txHash}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          View on Block Explorer
        </a>
      </div>,
      {
        position: "top-right",
        autoClose: 7000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  };
  return (
    <div className="mt-4" style={{ padding: "0px" }}>
      <div className="row">
        {repurchaseData?.map((item, index) => {
          if (item?.isAvatarActive === false) {
            return (
              <div className="col-12 col-md-6 col-xl-4 mb-4" key={index}>
                <div className="card-evc h-100 d-flex flex-column">
                  <div className="evc-avatar">
                    <img
                      src={getImgAsset(item?.avatarTokenId)}
                      alt=""
                      className="img-fluid w-100"
                      style={{
                        height: "100%",
                        width: "100%",
                        borderRadius: "10px",
                      }}
                    />
                  </div>
                  <div className="evc-info p-1 pb-0 h-100 d-flex flex-column justify-content-between">
                    <div className="d-flex gap-2 flex-wrap justify-content-between mb-2">
                      <a href="#" className="evc-title">
                        <div  className="evc-title" style={{ textAlign: "center" }}>
                          {""}
                          {item?.avatarTokenId >= 1 &&
                            item?.avatarTokenId <= 600000
                            ? t("Crypto Newbies")
                            : item?.avatarTokenId >= 600001 &&
                              item?.avatarTokenId<= 900000
                              ? t("Crypto Enthusiast")
                              : item?.avatarTokenId >= 900001 &&
                                item?.avatarTokenId <= 1100000
                                ? t("Crypto Entrepreneur")
                                : item?.avatarTokenId >= 1100001 &&
                                  item?.avatarTokenId <= 1200000
                                  ? t("Crypto Investor")
                                  : item?.avatarTokenId >= 1200001 &&
                                    item?.avatarTokenId <= 1250000
                                    ? t("Crypto King")
                                    : item?.avatarTokenId >= 1250001 &&
                                      item?.avatarTokenId <= 1270000
                                      ? t("Blockchain Mogul")
                                      : item?.avatarTokenId >= 1270001 &&
                                        item?.avatarTokenId <= 1280000
                                        ? t("Bitcoin Billionaire")
                                        : item?.avatarTokenId >= 1280001 &&
                                          item?.avatarTokenId <= 1285000
                                          ? t("CryptoCap Tycoon")
                            : null}
                        </div>
                      </a>
                    </div>

                    <div className="d-flex justify-content-between">
                      <div className="evc-price d-flex ">
                        <div
                          className="evc-price-title"
                          style={{ fontSize: "11px" }}
                        >
                          {t("PRICE")}
                        </div>

                        <div className="evc-bv-title" style={{ fontSize: "12px" }}>
                          <img
                            src={busd}
                            alt=""
                            className="img-fluid"
                            style={{ height:isMobile ? 30 : 14, width: isMobile ? 30 : 14 }}
                            />
                          {
                            evc_avatars[
                              `${getPricenVolume(item?.avatarTokenId)}`
                            ].price
                          }
                        </div>
                      </div>
                      <div className="evc-bv d-flex">
                        <div
                          className="evc-bv-title"
                          style={{ fontSize: "11px" }}
                        >
                          {t("BV")}
                        </div>
                        <div className="evc-bv-title" style={{ fontSize: "12px" }}>
                          <img
                            src={busd}
                            alt=""
                            className="img-fluid"
                            style={{ height:isMobile ? 30 : 14, width: isMobile ? 30 : 14 }}
                            />
                          {
                            evc_avatars[
                              `${getPricenVolume(item?.avatarTokenId)}`
                            ].bv
                          }
                        </div>
                      </div>
                    </div>

                    {Number(approveBUSDValue) < Number( evc_avatars[getPricenVolume(item?.avatarTokenId)].bv?.replace("$", "") || 0) ? (
                        <div className="d-grid gap-2 border-g">
                         <button
                          className="btn btn-dark-bg-custom evc-title "
                           type="button"
                            onClick={() => handleApprove()}
                             >
                             {t("Approve")}
                             </button>
                                </div>
                                ) : (
                                <div className="d-grid gap-2 border-g">
                                   <button
                                className="btn btn-dark-bg-custom evc-title"
                                type="button"
                                onClick={() => handleRepurchase(item?.avatarTokenId)}
    >                          
                                {t("Repurchase")}
                              </button>
                            </div>
                          )}

                  </div>
                </div>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
}

export default Repurchase;
