import { Link } from "react-router-dom";
import logo from "../../../assets/img/dashboard/dash-logo.svg";
import { ReactComponent as Icon1 } from "../../../assets/img/dashboard/icons/tokend-icon-1.svg";
import { VscBell, VscListSelection } from "react-icons/vsc";
import Web3 from "web3";
import { useState, useEffect, useRef } from "react";
import { getAccountNotification } from "../../../ContractAction/EVCNFTContractAction";
import { getBalanceEVC } from "../../../ContractAction/EVCStakeContractAction";
import ConnectButton from "../../../Pages/Home/ConnectButton";
import icon from "../../../assets/img/icons/icon.png";
import { useTranslation } from "react-i18next";
import { RxCross2 } from "react-icons/rx";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useWeb3Onboard } from "../../../config/context";

import notify_img from "../../Dashboard/Mint/Images/level2.svg";

import {
  DELETENOTIFICATIONBYID,
  AUTHUSERNAME,
  DELETENOTIFICATIONBYADDRESS,
  AUTHUSERPASSWORD,
} from "../../../ContractAction/ContractDependency";
import axios from "axios";
import { toast } from "react-toastify";
import { MdDelete } from "react-icons/md";
import useMediaQuery from "@mui/material/useMediaQuery";
import icon_2 from '../../../assets/img/dashboard/tread-logo.png'
import m_logo from '../../../assets/img/dashboard/img/mobile-logo.png'


const Navbar = ({ sideToggle, setSideToggle }) => {
  const [box, setBox] = useState(false); //
  const [isConnected, setIsConnected] = useState(false); //
  const [address, setAddress] = useState(""); //
  const [userEVCBalance, setUserEVCBalance] = useState("");

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const { t, i18n } = useTranslation();
  const dropdownRef = useRef(null);
  const { notiUpdated, setNotiUpdated } = useWeb3Onboard();
  const isMobile = useMediaQuery("(max-width:991px)");

  const languages = [
    { name: "EN", code: "en" },       // English
    { name: "DE", code: "de" },       // German
    { name: "RU", code: "ru" },       // Russian
    { name: "VI", code: "vi" },       // Vietnamese
    { name: "ZH", code: "zh" },       // Chinese
    { name: "ES", code: "es" },       // Spanish
    { name: "KO", code: "ko" },       // Korean
    { name: "JA", code: "ja" },       // Japanese
    { name: "TH", code: "th" },       // Thai
    { name: "FR", code: "fr" }        // French
  ];
  const [selectedLanguage, setSelectedLanguage] = useState(() => {
    const savedLangCode = localStorage.getItem("i18nextLng");
    return savedLangCode
      ? languages.find((lang) => lang.code === savedLangCode)
      : languages[0];
  });



  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };


  useEffect(() => {
    const getAccountNotifications = async () => {
      const notificationData = await getAccountNotification();
      setNotifications(notificationData || []);
      setNotiUpdated(false); // Reset notiUpdated to false after fetching
    };

    getAccountNotifications();
  }, [notiUpdated]);

  //dev: Use useEffect
  useEffect(() => {
    const AvailableBalanceEVC = async () => {
      let EVCBalance = await getBalanceEVC();
      setUserEVCBalance(EVCBalance);
    };
    AvailableBalanceEVC();
    const updateLocalValueClaimOrWithdraw = setInterval(
      AvailableBalanceEVC,
      10000
    );
    return () => clearInterval(updateLocalValueClaimOrWithdraw);
  }, []);

  //dev: detect Current Provider Metamask
  const detectCurrentProviderMetamask = (e) => {
    //
    e.preventDefault(); //
    let provider; //

    if (typeof window.ethereum !== "undefined") {
      //
      provider = window.ethereum.providers.find(
        (provider) => provider.isMetaMask
      ); //
    } else {
      //
    } //
    return provider; //
  };

  //dev: Connect Metamask Wallet
  const onConnectMetamask = async (e) => {
    e.preventDefault();
    try {
      const currentProvider = detectCurrentProviderMetamask(e);
      if (currentProvider) {
        await currentProvider.request({ method: "eth_requestAccounts" });
        const web3 = new Web3(currentProvider);
        const userAccount = await web3.eth.getAccounts();
        const account = userAccount[0];
        setAddress(account);
        setIsConnected(true);
        setBox(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  //dev:  Disconnect Metamask Wallet
  const onDisconnect = () => {
    setIsConnected(false);
    // setEthBalance();
  };

  const wallet = () => {
    //
    setBox(true); //
  };

  const cancel = () => {
    //
    setBox(false); //
  }; //

  //dev: Toggle Sidebar

  const sidebarToggle = (e) => {
    setSideToggle(!sideToggle);
  };

  const handleDeleteNotificationById = async (id) => {
    try {
      const response = await axios({
        url: `${DELETENOTIFICATIONBYID}/${id}`,
        method: "DELETE",
        auth: {
          username: AUTHUSERNAME,
          password: AUTHUSERPASSWORD,
        },
      });
      if (response?.data?.status === true) {
        toast.success("Notification deleted successfully");
        setNotiUpdated(true); // Trigger re-fetch of notifications
      }
    } catch (err) {
      console.error("Error deleting notification:", err);
      // Optionally handle error with a toast or other feedback
    }
  };
  const handleDeleteAllNotifications = async () => {
    try {
      const address = window.localStorage.getItem("connectedAccount");
      const response = await axios({
        url: `${DELETENOTIFICATIONBYADDRESS}/${address}`,
        method: "DELETE",
        auth: {
          username: AUTHUSERNAME,
          password: AUTHUSERPASSWORD,
        },
      });
      if (response?.data?.status === true) {
        toast.success("Notifications deleted successfully");
        setNotiUpdated(true); // Trigger re-fetch of notifications
      }
    } catch (err) {
      console.error("Error deleting all notifications:", err);
      // Optionally handle error with a toast or other feedback
    }
  };

  const handleSelect = (eventKey) => {
    const selectedLang = languages.find((lang) => lang.code === eventKey);
    setSelectedLanguage(selectedLang);
    changeLanguage(eventKey);
  };
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="cbd-navbar">
      <div className="d-flex align-items-start justify-content-between">
        <div className="cbd-navbar-left">
          {/* <Link to="/" className="d-flex px-sm-2"> */}
          <Link to="/" className="d-flex">
            <img src={logo} alt="" className="img-fluid nav-logo d-none d-md-block" />
            <img src={m_logo} alt="" className="img-fluid nav-logo d-md-none" />
          </Link>
        </div>
        <div className="cbd-navbar-right d-flex align-items-center">
          <div className="d-flex align-items-center gap-3 me-auto">
            <div className="page-title  me-auto d-none d-md-flex flex-left">
              {t("Dashboard")}
            </div>
            <div className="cbd-navbar-profile d-lg-flex align-items-center gap-2 d-none">
              <div className="cursor-pointer icon-user p-2 d-none d-lg-flex">
                <img src={icon} alt="" />
              </div>
              <div>
                <div className="nav-tata text-sm opacity-75">
                  {t("HOLDER")}
                </div>
                <div className="nav-tata text-sm">0</div>
              </div>
            </div>
            <div className="cbd-navbar-profile d-lg-flex align-items-center gap-2 d-none">
              <div className="cursor-pointer icon-user d-none d-lg-flex">
                <img src={icon_2} alt="" />
              </div>
              <div style={{ width: isMobile ? "100px" : "initial" }}>
                <div className="nav-tata text-uppercase text-sm opacity-75" >
                  {t("TRND TOKEN")}
                </div>
                <div className="nav-tata text-sm">
                  {Number(userEVCBalance).toLocaleString(undefined, {
                    maximumFractionDigits: 4,
                  })}
                </div>
              </div>
              {/* {!isMobile && (
                  <div>
                    <NavDropdown
                      title={selectedLanguage.name}
                      id="navbarScrollingDropdown"
                      onSelect={handleSelect}
                      style={{ fontSize: "15px" }}
                    >
                      {languages.map((lang) => (
                        <NavDropdown.Item key={lang.code} eventKey={lang.code}>
                          {lang.name}
                        </NavDropdown.Item>
                      ))}
                    </NavDropdown>
                  </div>
                )} */}
            </div>
          </div>
          <div className="mobile-icons custom-gap-md d-flex align-items-center justify-content-end ">
            <div className="me-3 d-none d-lg-block">
              <div className="lang">
                <NavDropdown
                  title={selectedLanguage.name}
                  id="navbarScrollingDropdown"
                  onSelect={handleSelect}
                >
                  {languages.map((lang) => (
                    <NavDropdown.Item key={lang.code} eventKey={lang.code}>
                      {lang.name}
                    </NavDropdown.Item>
                  ))}
                </NavDropdown>
              </div>
            </div>
            <div className="position-relative me-2 me-sm-3 me-lg-4" ref={dropdownRef}>
              <div className="cursor-pointer icon-square icon-circle icon-notification" onClick={toggleDropdown}>
                <VscBell />
              </div>
              <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger d-none d-md-block">
                <span style={{ fontSize: "10px" }}>
                  {notifications?.length}
                </span>
              </span>
              {isDropdownOpen && notifications.length > 0 && (
                <div className="notify position-absolute top-100 end-0">
                  <div className="notify-header d-flex align-item-center justify-content-between">
                    <h4 className="mb-0">Notifications</h4>
                    <button className="d-flex align-items-center " onClick={() => handleDeleteAllNotifications()}>Clear All</button>
                  </div>
                  <div className="notify-content overflow-auto">
                    {notifications.map((notification, index) => (
                      <div y={index} className="notify-item py-3 d-flex align-items-center">
                        {/* <div className="notify-item-img rounded-pill">
                          <img src={notify_img} alt="" />
                        </div> */}
                        <div className="notify-item-content">
                          <p className="mb-0">{notification.notification}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <ConnectButton />
            <div className="position-relative">
              <div className={`cursor-pointer position-absolute top-0 start-0 h-100 w-100 ${sideToggle ? "" : "d-none"}`}></div>
              <div className="thumberger cursor-pointer icon-square d-flex d-lg-none p-2" onClick={sidebarToggle}>
                <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.866211 1.50494H18.187" stroke="#A3A3A3" stroke-width="1.62383" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M0.866211 8.00049H18.187" stroke="#A3A3A3" stroke-width="1.62383" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M0.866211 14.4952H18.187" stroke="#A3A3A3" stroke-width="1.62383" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
