import React, { useEffect } from 'react'
import Appbar from '../../Components/Global/Appbar';
import Footer from '../../Components/Global/Footer';
import Hero from './Hero';
import About from './About';
import Tockenomics from './Tockenomics';
import NftSlider from './NftSlider';
import How from './How';
import Roadmap from './Roadmap';
import Affiliate from './Affiliate';
import Socials from './Socials';

import 'swiper/css';
import Products from './Products';

const Home = ({ title }) => {

  useEffect(() => {
    document.title = title ? title : 'TT Avatars | Home';
  }, [])


  return (
    <>
      <Appbar mobile="offcanvas" />
      <Hero />
      <About />
      <Products />
      <Tockenomics />
      <NftSlider />
      <How />
      <Roadmap />
      {/* <Affiliate /> */} 
      <Socials />
      <Footer />

    </>
  )
}

export default Home;