import React from "react";
import SectionTitle from "../../Components/Single/SectionTitle";
import TokenomicsChart from "../../Components/Single/Chart";
import tokenomics from "../../assets/img/regular/tokenomics.svg";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";
//dev: Start Tockenomics Page
const Tockenomics = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width:991px)");

  return (
    <div
      className="section-gap position-relative-bg-custom tokenomics"
      id="tok"
      // style={{ backgroundImage: `url(${tokenomics})`, paddingTop: "120px" }}
    >
      <div
        className="container align-items-center justify-content-center mb-0"  
        style={{ marginBottom: "0px", paddingBottom: "0px" }}
      >
        <SectionTitle
          pre={t("Tokenomics")}
          main={t("Trend Token serves as the native currency within our")}
          main1={t(
            "ecosystem, enabling you to engage with a range of exciting"
          )}
          main2={t("products services and benefits.")}
          fontweightmain1={"500"}
          fontweightmain={"500"}
          className="text-center tk-text"
          fontweightpre={"700"}
          subTitleSize={isMobile ? "18px" : "24px"}
          subTitleSize1={isMobile ? "18px" : "24px"}
          subFull={"text-center"}
        />
      </div>
      {/* dev:TokenomicsChart  */}
      <div className="d-flex flex-row align-item-center justify-content-center mt-4 mt-xl-5 w-100">
        <TokenomicsChart />
      </div>
    </div>
  );
};

export default Tockenomics;
