import Web3 from "web3";
import { abiEVCTokenStake } from "./ABI/EVCStake"
import { RPCURL, ContractAddressDead, ContractAddressTRNDToken, CurrentChainID, ContractAddressTRNDVEST,getCurrentAccount, getCurrentSigner } from "./ContractDependency.js"
import { ethers } from 'ethers';
import { ethersProvider } from "../config/context.js";

const abiEVCToken = abiEVCTokenStake

var web3 = new Web3(new Web3.providers.HttpProvider(RPCURL));



// export const getBalanceEVC = async () => {
//   // console.log("getBalanceEVC");
//   let ChecksumAddress = web3.utils.isAddress(ContractAddressTRNDToken);
//   console.log("ChecksumAddress", ChecksumAddress, ContractAddressTRNDToken);
//   let contracts = new web3.eth.Contract(abiEVCToken, ContractAddressTRNDToken);
//   const accounts = await window.ethereum.request({
//     method: "eth_accounts",
//   });
//   const account = accounts[0];
//   // console.log("getBalanceEVC_account", account);
//   const response = await contracts.methods.balanceOf(account).call();
//   // console.log("getBalanceEVC", typeof response);
//   let balanceOfEvc = web3.utils.fromWei(response, "ether");
//   let balanceOfEVC = Number(parseFloat(balanceOfEvc).toFixed(2));
//   console.log("balanceOfEVC", balanceOfEVC);
//   return balanceOfEvc;
// };
// getBalanceEVC()

//Sayyed Code
export const getBalanceEVC = async () => {
  const signer = await getCurrentSigner();
  let ChecksumAddress = ethers.utils.isAddress(ContractAddressTRNDToken);
  console.log("ChecksumAddress", ChecksumAddress, ContractAddressTRNDToken);
  let contracts = new ethers.Contract(ContractAddressTRNDToken, abiEVCToken, signer);
  const account = await getCurrentAccount();
  const response =  await contracts.balanceOf(account);
  let balanceOfEvc = ethers.utils.formatEther(response);
  let balanceOfEVC =  Number(parseFloat(balanceOfEvc).toFixed(2));
  console.log("balanceOfEVC", balanceOfEVC);
  return balanceOfEvc;
};
getBalanceEVC()

//Sayyed Code
// export const getAvailableBalanceEVC = async () => {
//   console.log("getAvailableBalanceEVC");
//   let contracts = new web3.eth.Contract(abiEVCToken, ContractAddressTRNDToken);
//   const response = await contracts.methods.balanceOf(ContractAddressTRNDToken).call();
//   console.log("getAvailableBalanceEVC", response);
//   let BalanceOfEVCflex = await web3.utils.fromWei(response, "ether");
//   let BalanceOfEVCFlex = parseFloat(BalanceOfEVCflex).toFixed(2);
//   return BalanceOfEVCFlex;
// };
//Shreyash Code
export const getAvailableBalanceEVC = async () => {
  console.log("getAvailableBalanceEVC");
  if (!ethersProvider) {
    console.error("ethersProvider is not available");
    return;
  }
  const contracts = new ethers.Contract(ContractAddressTRNDToken, abiEVCToken, ethersProvider);
  try {
    const response = await contracts.balanceOf(ContractAddressTRNDToken);
    console.log("getAvailableBalanceEVC", response.toString());
    let balanceOfEVC = ethers.utils.formatEther(response);
    let balanceOfEVCFlex = parseFloat(balanceOfEVC).toFixed(2);
    return balanceOfEVCFlex;
  } catch (error) {
    console.error("Error fetching balance:", error);
    return null; // Handle error as needed
  }
};

//Sayyed Code
// export const getVestingBalanceEVC = async () => {
//   console.log("getVestingBalanceEVC");
//   let contracts = new web3.eth.Contract(abiEVCToken, ContractAddressTRNDToken);
//   const response = await contracts.methods.balanceOf(ContractAddressTRNDVEST).call();
//   console.log("getVestingBalanceEVC", response);
//   let vestingEvcBalance = web3.utils.fromWei(response, "ether");
//   let vestingEVCBalance = Number(parseFloat(vestingEvcBalance).toFixed(2));
//   console.log("vestingEVCBalance", vestingEVCBalance)
//   return vestingEVCBalance;
// };

//Shreyash Code
export const getVestingBalanceEVC = async () => {
  console.log("getVestingBalanceEVC");
  if (!ethersProvider) {
    console.error("ethersProvider is not available");
    return;
  }  const contracts = new ethers.Contract(ContractAddressTRNDToken, abiEVCToken, ethersProvider);
  try {
    const response = await contracts.balanceOf(ContractAddressTRNDVEST);
    console.log("getVestingBalanceEVC", response.toString());
    let vestingEvcBalance = ethers.utils.formatEther(response);
    let vestingEVCBalance = Number(parseFloat(vestingEvcBalance).toFixed(2));
    console.log("vestingEVCBalance", vestingEVCBalance);
    return vestingEVCBalance;
  } catch (error) {
    console.error("Error fetching vesting balance:", error);
    return null; // Handle error as needed
  }
};

//Sayyed Code
// const getContractOwner = async () => {
//   console.log("getContractOwner");
//   let contracts = new web3.eth.Contract(abiEVCToken, ContractAddressTRNDToken);
//   const response = await contracts.methods.owner().call();
//   console.log("response", response);
//   return response;
// };

//Shreyas Code
export const getContractOwner = async () => {
  console.log("getContractOwner");
  if (!ethersProvider) {
    console.error("ethersProvider is not available");
    return;
  }
  const contracts = new ethers.Contract(ContractAddressTRNDToken, abiEVCToken, ethersProvider);
  try {
    const response = await contracts.owner();
    console.log("response", response);
    return response;
  } catch (error) {
    console.error("Error fetching contract owner:", error);
    return null; // Handle error as needed
  }
};

//Sayyyed Code
// export const getOwnerBalance = async () => {
//   console.log("getOwnerBalance");
//   let contracts = new web3.eth.Contract(abiEVCToken, ContractAddressTRNDToken);
//   const ownerAddress = await getContractOwner();
//   const response = await contracts.methods.balanceOf(ownerAddress).call();
//   console.log("getOwnerBalance", response);
//   let ownerEvcBalance = web3.utils.fromWei(response, "ether");
//   let ownerEVCBalance = Number(parseFloat(ownerEvcBalance).toFixed(2));
//   return ownerEVCBalance;
// };

//Shreyash Code
export const getOwnerBalance = async () => {
  console.log("getOwnerBalance");
  if (!ethersProvider) {
    console.error("ethersProvider is not available");
    return;
  }
  const contracts = new ethers.Contract(ContractAddressTRNDToken, abiEVCToken, ethersProvider);
  try {
    const ownerAddress = await getContractOwner();
    const response = await contracts.balanceOf(ownerAddress);
    console.log("getOwnerBalance", response);
    let ownerEvcBalance = ethers.utils.formatEther(response); // Convert from wei to ether
    let ownerEVCBalance = Number(parseFloat(ownerEvcBalance).toFixed(2));
    return ownerEVCBalance;
  } catch (error) {
    console.error("Error fetching owner balance:", error);
    return null; // Handle error as needed
  }
};
//Sayyed Code
// export const getBurnEVCBalance = async () => {
//   console.log("getBurnEVCBalance");
//   let contracts = new web3.eth.Contract(abiEVCToken, ContractAddressTRNDToken);
//   const burnAddress = ContractAddressDead;
//   const response = await contracts.methods.balanceOf(burnAddress).call();
//   console.log("getBurnEVCBalance", response);
//   let burnevcBalance = web3.utils.fromWei(response, "ether");
//   console.log("burnevcBalance", burnevcBalance)
//   let burnEVCBalance = Number(parseFloat(burnevcBalance).toFixed(2));
//   console.log("burnEVCBalance", typeof burnEVCBalance)
//   return burnEVCBalance;
// };
//Shreyash Code
export const getBurnEVCBalance = async () => {
  console.log("getBurnEVCBalance");
  if (!ethersProvider) {
    console.error("ethersProvider is not available");
    return;
  }
  const contracts = new ethers.Contract(ContractAddressTRNDToken, abiEVCToken, ethersProvider);
  const burnAddress = ContractAddressDead;
  try {
    const response = await contracts.balanceOf(burnAddress);
    console.log("getBurnEVCBalance", response);

    let burnEvcBalance = ethers.utils.formatEther(response); // Convert from wei to ether
    let burnEVCBalance = Number(parseFloat(burnEvcBalance).toFixed(2));
    console.log("burnEVCBalance", burnEVCBalance, typeof burnEVCBalance);
    return burnEVCBalance;
  } catch (error) {
    console.error("Error fetching burn EVC balance:", error);
    return null; // Handle error as needed
  }
};

getBurnEVCBalance()


//Sayyed Code
// export const allowanceEVC = async (_contractAddress) => {
//   console.log("allowanceEVC")
//   try {
//     if (window.ethereum) {
//       const web3 = new Web3(window.ethereum);
//       // Get the selected account
//       const accounts = await window.ethereum.request({
//         method: "eth_accounts",
//       });
//       const account = accounts[0];
//       const contracts = new web3.eth.Contract(abiEVCToken, ContractAddressTRNDToken);
//       console.log("i'm here", contracts.methods);
//       console.log("account", account);
//       console.log("Contractaddress", _contractAddress);
//       const response = await contracts.methods.allowance(account, _contractAddress).call();
//       console.log("response", response)
//       const allowance = web3.utils.fromWei(response, 'ether')
//       console.log("EVCAllowance", allowance);
//       return allowance;
//     }
//   } catch (error) {
//     return error;
//   }
// };

//Shreyash Code
export const allowanceEVC = async (_contractAddress) => {
  console.log("allowanceEVC");
  if (!ethersProvider) {
    console.error("ethersProvider is not available");
    return null;
  }
  try {
    const signer = await getCurrentSigner();
    const account = await getCurrentAccount();
    const contracts = new ethers.Contract(ContractAddressTRNDToken, abiEVCToken, signer);
    console.log("account", account);
    console.log("ContractAddress", _contractAddress);
    const response = await contracts.allowance(account, _contractAddress);
    console.log("response", response);
    const allowance = ethers.utils.formatEther(response); // Convert from wei to ether
    console.log("EVCAllowance", allowance);
    return allowance;
  } catch (error) {
    console.error("Error fetching allowance:", error);
    return null; // Handle error as needed
  }
};

//Sayyed Code
// export const getEVCBalance = async () => {
//   console.log("getEVCBalance")
//   try {
//     if (window.ethereum) {
//       const web3 = new Web3(window.ethereum);
//       // Get the selected account
//       const accounts = await window.ethereum.request({
//         method: "eth_accounts",
//       });
//       const account = accounts[0];
//       const contracts = new web3.eth.Contract(abiEVCToken, ContractAddressTRNDToken);
//       console.log("ContractAddressTRNDToken", ContractAddressTRNDToken)
//       console.log("i'm here", contracts.methods);
//       console.log("account", account);
//       const response = await contracts.methods.balanceOf(account).call();
//       console.log("mybalanceineth", response)
//       const balance = Number((web3.utils.fromWei(response, 'ether')));
//       console.log("EVCBalance", balance);
//       return balance;
//     }
//   } catch (error) {
//     console.log("balance", error);
//   }
// };

//Shreyash Code
export const getEVCBalance = async () => {
  console.log("getEVCBalance");
  if (!ethersProvider) {
    console.error("ethersProvider is not available");
    return null;
  }
  try {
    const signer = await getCurrentSigner();
    const account = await getCurrentAccount();
    const contracts = new ethers.Contract(ContractAddressTRNDToken, abiEVCToken, signer);
    console.log("ContractAddressTRNDToken", ContractAddressTRNDToken);
    console.log("account", account);
    const response = await contracts.balanceOf(account);
    console.log("mybalanceinwei", response.toString());
    const balance = parseFloat(ethers.utils.formatEther(response)); // Convert from wei to ether
    console.log("EVCBalance", balance);
    return balance;
  } catch (error) {
    console.error("Error fetching balance:", error);
    return null; // Handle error as needed
  }
};

//Sayyed Code
// export const getEVCZeroAddBal = async () => {
//   console.log("getEVCZeroAddBal")
//   try {
//     if (window.ethereum) {
//       const web3 = new Web3(window.ethereum);
//       const contracts = new web3.eth.Contract(abiEVCToken, ContractAddressTRNDToken);
//       const zeroAdd = ContractAddressDead
//       const response = await contracts.methods.balanceOf(zeroAdd).call();
//       const balance = Number((web3.utils.fromWei(response, 'ether')));
//       console.log("EVCZeroAddBal", balance);
//       return balance;
//     }
//   } catch (error) {
//     console.log("EVCZeroAddBalERR", error);
//   }
// };

//Shreyash Code
export const getEVCZeroAddBal = async () => {
  console.log("getEVCZeroAddBal");
  if (!ethersProvider) {
    console.error("ethersProvider is not available");
    return null;
  }
  try {
    const contracts = new ethers.Contract(ContractAddressTRNDToken, abiEVCToken, ethersProvider);
    const zeroAdd = ContractAddressDead;
    const response = await contracts.balanceOf(zeroAdd);
    const balance = parseFloat(ethers.utils.formatEther(response)); // Convert from wei to ether
    console.log("EVCZeroAddBal", balance);
    return balance;
  } catch (error) {
    console.error("EVCZeroAddBalERR", error);
    return null; // Handle error as needed
  }
};

//Sayyeded Code
// export const getEVCTotalSupply = async () => {
//   console.log("getEVCTotalSupply")
//   try {
//     if (window.ethereum) {
//       const web3 = new Web3(window.ethereum);
//       const contracts = new web3.eth.Contract(abiEVCToken, ContractAddressTRNDToken);
//       const response = await contracts.methods.TotalSupplyLimit().call();
//       const balance = Number((web3.utils.fromWei(response, 'ether')));
//       console.log("EVCTotalSupply", balance);
//       return balance;
//     }
//   } catch (error) {
//     console.log("EVCTotalSupplyERR", error);
//   }
// };

//Shreyash Code
export const getEVCTotalSupply = async () => {
  console.log("getEVCTotalSupply");
  if (!ethersProvider) {
    console.error("ethersProvider is not available");
    return null;
  }
  try {
    const contracts = new ethers.Contract(ContractAddressTRNDToken, abiEVCToken, ethersProvider);
    const response = await contracts.TotalSupplyLimit();
    const balance = parseFloat(ethers.utils.formatEther(response)); // Convert from wei to ether
    console.log("EVCTotalSupply", balance);
    return balance;
  } catch (error) {
    console.error("EVCTotalSupplyERR", error);
    return null; // Handle error as needed
  }
};

//Sayyed Code
// export const getEVCCirculatingSupply = async () => {
//   // console.log("getEVCCirculatingSupply");
//   try {
//     if (window.ethereum) {
//       const web3 = new Web3(window.ethereum);
//       const contracts = new web3.eth.Contract(abiEVCToken, ContractAddressTRNDToken);
//       // Get the total supply
//       const totalSupplyResponse = await contracts.methods.totalSupply().call();
//       const totalSupply = Number(web3.utils.fromWei(totalSupplyResponse, 'ether'));
//       // Get the balance of the zero address
//       const zeroAdd = ContractAddressDead;
//       const zeroAddressBalanceResponse = await contracts.methods.balanceOf(zeroAdd).call();
//       const zeroAddressBalance = Number(web3.utils.fromWei(zeroAddressBalanceResponse, 'ether'));
//       // Calculate the circulating supply by subtracting the zero address balance from the total supply
//       const circulatingSupply = totalSupply - zeroAddressBalance;
//       // console.log("EVCCirculatingSupply", circulatingSupply);
//       return circulatingSupply;
//     }
//   } catch (error) {
//     console.log("EVCCirculatingSupplyERR", error);
//   }
// };
//Shreyash Code
export const getEVCCirculatingSupply = async () => {
  console.log("getEVCCirculatingSupply");
  if (!ethersProvider) {
    console.error("ethersProvider is not available");
    return null;
  }
  try {
    const contracts = new ethers.Contract(ContractAddressTRNDToken, abiEVCToken, ethersProvider);
    const totalSupplyResponse = await contracts.totalSupply();
    const totalSupply = parseFloat(ethers.utils.formatEther(totalSupplyResponse));
    const zeroAdd = ContractAddressDead;
    const zeroAddressBalanceResponse = await contracts.balanceOf(zeroAdd);
    const zeroAddressBalance = parseFloat(ethers.utils.formatEther(zeroAddressBalanceResponse));
    const circulatingSupply = totalSupply - zeroAddressBalance;
    console.log("EVCCirculatingSupply", circulatingSupply);
    return circulatingSupply;
  } catch (error) {
    console.error("EVCCirculatingSupplyERR", error);
    return null; // Handle error as needed
  }
};

getEVCCirculatingSupply()

//Sayyed Code
// export const getTotalEvcCirculatingSupply = async () => {
//   // console.log("getTotalEvcCirculatingSupply");
//   let contracts = new web3.eth.Contract(abiEVCToken, ContractAddressTRNDToken);
//   const totalEvcCirculatingSupplyInWei = await contracts.methods.circulatingSupply().call();
//   // console.log("totalEvcCirculatingSupplyInWei", totalEvcCirculatingSupplyInWei);
//   const totalEvcCirculatingSupplyInEth = web3.utils.fromWei(totalEvcCirculatingSupplyInWei, 'ether')
//   // console.log("totalEvcCirculatingSupplyInEth", totalEvcCirculatingSupplyInEth);
//   return totalEvcCirculatingSupplyInEth;
// }

//Shreyash Code
export const getTotalEvcCirculatingSupply = async () => {
  console.log("getTotalEvcCirculatingSupply");
  if (!ethersProvider) {
    console.error("ethersProvider is not available");
    return null;
  }
  try {
    const contracts = new ethers.Contract(ContractAddressTRNDToken, abiEVCToken, ethersProvider);
    const totalEvcCirculatingSupplyInWei = await contracts.circulatingSupply();
    const totalEvcCirculatingSupplyInEth = parseFloat(ethers.utils.formatEther(totalEvcCirculatingSupplyInWei));
    console.log("totalEvcCirculatingSupplyInEth", totalEvcCirculatingSupplyInEth);
    return totalEvcCirculatingSupplyInEth;
  } catch (error) {
    console.error("getTotalEvcCirculatingSupplyERR", error);
    return null; // Handle error as needed
  }
};

getTotalEvcCirculatingSupply()





















// NOTE: EVC deployed for providing liquidity remove it when addressess are being changed 
// and also replace variable in allownceEVC function and also remove ABIEVCToken in EVCStake.js in ABI file


//dev: Flexible Staking

// export const setStakeFlex = async (_amount) => {
//   console.log("_amount", _amount);
//   if (window.ethereum) {
//     const web3 = new Web3(window.ethereum);
//     await window.ethereum.request({ method: "eth_requestAccounts" });
//     // Get the selected account
//     const accounts = await window.ethereum.request({ method: "eth_accounts" });
//     const account = accounts[0];
//     const currentChainId = await web3.eth.net.getId();
//     if (currentChainId !== CurrentChainID) {
//       await web3.currentProvider.request({
//         method: "wallet_switchEthereumChain",
//         params: [{ chainId: Web3.utils.toHex(CurrentChainID) }],
//       });
//     }
//     const contracts = new web3.eth.Contract(abiEVCToken, ContractAddressTRNDToken);
//     console.log(contracts);
//     const amount = _amount;
//     console.log("amountStakeFlex", amount);
//     const _finalamount = web3.utils.toWei(_amount);
//     console.log("_finalamountStakeFlex", _finalamount);
//     const planId = 1;
//     console.log("planId", planId)
//     const stakeAmount = await contracts.methods.stakeFlex(_finalamount, planId).send({ from: account });
//     console.log("setStakeFlex", stakeAmount);
//   }
// };

//dev: get Staked Ids Flex Stake
// export const getStakedIdsFlexStake = async () => {
//   console.log("getStakedIdsFlexStake");
//   let contracts = new web3.eth.Contract(abiEVCToken, ContractAddressTRNDToken);
//   const accounts = await window.ethereum.request({
//     method: "eth_accounts",
//   });
//   const account = accounts[0];
//   console.log("getStakedIdsFlexStake", account);
//   const response = await contracts.methods.getStakedflexId(account).call();
//   const resCopy = [...response].sort((a, b) => a.localeCompare(b, undefined, { numeric: true }))
//   console.log("getStakedIdsFlexStake", resCopy);
//   return resCopy;
// };

// export const getFlexUnStakedBeforeTimeIds = async () => {
//   console.log("getFlexUnStakedBeforeTimeIds");
//   let contracts = new web3.eth.Contract(abiEVCToken, ContractAddressTRNDToken);
//   const accounts = await window.ethereum.request({
//     method: "eth_accounts",
//   });
//   const account = accounts[0];
//   console.log("getFlexUnStakedBeforeTimeIds_account", account);
//   const response = await contracts.methods.getFlexUnstakeBeforeTime(account).call();
//   const resCopy = [...response].sort((a, b) => a.localeCompare(b, undefined, { numeric: true }))
//   console.log("getFlexUnStakedBeforeTimeIds_res", resCopy);
//   return resCopy;
// };

// export const getFlexUnStakedBeforeTimeInfo = async () => {
//   const flexUnStakedBeforeTimeInfo = [];
//   console.log("getFlexUnStakedBeforeTimeInfo");
//   let contracts = new web3.eth.Contract(abiEVCToken, ContractAddressTRNDToken);
//   const accounts = await window.ethereum.request({
//     method: "eth_accounts",
//   });
//   const account = accounts[0];
//   console.log("getFlexUnStakedBeforeTimeInfo_account", account);
//   const ids = await getFlexUnStakedBeforeTimeIds();
//   for(var i = 0; i < ids.length; i++){
//     const response = await contracts.methods.flexUnstakeBeforeTimeInfo(account, ids[i]).call();
//     flexUnStakedBeforeTimeInfo.push(response)
//     console.log("response", response)
//   }
//   console.log("getFlexUnStakedBeforeTimeInfo_res", flexUnStakedBeforeTimeInfo);
//   return flexUnStakedBeforeTimeInfo;
// };

//dev: get User Staked Flex
// export const getUserStakedFlex = async () => {
//   const stakeDetail = []
//   console.log("getUserStakedFix");
//   let contracts = new web3.eth.Contract(abiEVCToken, ContractAddressTRNDToken);
//   const accounts = await window.ethereum.request({
//     method: "eth_accounts",
//   });
//   const account = accounts[0];
//   console.log("getUserStakedFix_account", account);
//   const ids = await getStakedIdsFlexStake();
//   console.log("getIDS", ids);
//   for (var i = 0; i < ids.length; i++) {
//     const response = await contracts.methods.userStakedFlex(account, ids[i]).call();
//     stakeDetail.push(response)
//     console.log("response", response)
//   }
//   console.log("getUserStakedFixnoloop_res", stakeDetail);
//   return stakeDetail;
// };

//dev: set Unstake Flex
// export const setUnstakeFlex = async (_id) => {
//   if (window.ethereum) {
//     const web3 = new Web3(window.ethereum);
//     await window.ethereum.request({ method: "eth_requestAccounts" });
//     // Get the selected account
//     const accounts = await window.ethereum.request({ method: "eth_accounts" });
//     const account = accounts[0];
//     const currentChainId = await web3.eth.net.getId();
//     if (currentChainId !== CurrentChainID) {
//       await web3.currentProvider.request({
//         method: "wallet_switchEthereumChain",
//         params: [{ chainId: Web3.utils.toHex(CurrentChainID) }],
//       });
//     }
//     const contracts = new web3.eth.Contract(abiEVCToken, ContractAddressTRNDToken);
//     console.log(contracts);
//     console.log("id", _id);
//     const unstakeAmount = await contracts.methods.unstakeFlex(_id).send({ from: account });
//     console.log("unstakeFlex", unstakeAmount);
//   }
// };

//dev: set Claim Reward Flex
// export const setClaimRewardFlex = async (_id) => {
//   if (window.ethereum) {
//     const web3 = new Web3(window.ethereum);
//     await window.ethereum.request({ method: "eth_requestAccounts" });
//     // Get the selected account
//     const accounts = await window.ethereum.request({ method: "eth_accounts" });
//     const account = accounts[0];
//     const currentChainId = await web3.eth.net.getId();
//     if (currentChainId !== CurrentChainID) {
//       await web3.currentProvider.request({
//         method: "wallet_switchEthereumChain",
//         params: [{ chainId: Web3.utils.toHex(CurrentChainID) }],
//       });
//     }
//     const contracts = new web3.eth.Contract(abiEVCToken, ContractAddressTRNDToken);
//     console.log(contracts);
//     console.log("id", _id);
//     const claimrewardFlex = await contracts.methods.claimRewardFlex(_id).send({ from: account });
//     console.log("claimrewardFlex", claimrewardFlex);
//   }
// };

//dev: get Deposit Info Stake Flex
// function defined never used nor such view function in new contract
// export const getDepositInfoStakeFlex = async () => {
//   console.log("getDepositInfoStakeFlex");
//   let contracts = new web3.eth.Contract(abiEVCToken, ContractAddressTRNDToken);
//   const accounts = await window.ethereum.request({
//     method: "eth_accounts",
//   });
//   const account = accounts[0];
//   console.log("getDepositInfoStakeFlex_account", account);
//   const response = await contracts.methods.getDepositInfoFlex(account).call();
//   console.log("response", response);
//   let res = response._stake;
//   console.log("getDepositInfoStakeFlex_stake", res);
//   let stakeFlexTotalDeposit = await web3.utils.fromWei(res, "ether");
//   return stakeFlexTotalDeposit;
// };

//dev: get Deposit At Stake Flex
// function defined never used nor such view function in new contract
// export const getDepositAtStakeFlex = async () => {
//   console.log("getDepositAtStakeFlex");
//   let contracts = new web3.eth.Contract(abiEVCToken, ContractAddressTRNDToken);
//   const response = await contracts.methods.getDepositAtFlex().call();
//   console.log("getDepositAtStakeFlex_DepositAt", response);
//   return response;
// };

// export const claimLockStakeFlex = async () => {
//   console.log("claimLockStakeFlex");
//   let contracts = new web3.eth.Contract(abiEVCToken, ContractAddressTRNDToken);
//   const response = await contracts.methods.claimLockFlex().call();
//   console.log("claimLockStakeFlex", response);
//   return response;
// };

// export const getApyFlex = async () => {
//   console.log("getApyFlex");
//   let contracts = new web3.eth.Contract(abiEVCToken, ContractAddressTRNDToken);
//   const response = await contracts.methods.getFlexApr(1).call();
//   console.log("getApyFlex", response);
//   const apy = (response / 100).toLocaleString(undefined, {maximumFractionDigits:2});
//   console.log("apy", apy);
//   return apy;
// };



//dev: Fix Staking

// export const setStakeFix = async (_amount, _id) => {
//   console.log("_amountFix", _amount);
//   console.log("_idFix", _id);
//   if (window.ethereum) {
//     const web3 = new Web3(window.ethereum);
//     await window.ethereum.request({ method: "eth_requestAccounts" });
//     // Get the selected account
//     const accounts = await window.ethereum.request({ method: "eth_accounts" });
//     const account = accounts[0];
//     const currentChainId = await web3.eth.net.getId();
//     if (currentChainId !== CurrentChainID) {
//       await web3.currentProvider.request({
//         method: "wallet_switchEthereumChain",
//         params: [{ chainId: Web3.utils.toHex(CurrentChainID) }],
//       });
//     }
//     const contracts = new web3.eth.Contract(abiEVCToken, ContractAddressTRNDToken);
//     console.log(contracts);
//     const amount = _amount;
//     console.log("amountStakeFix", amount);
//     const _amounttowei = web3.utils.toWei(_amount);
//     console.log("_amounttoweiFix", _amounttowei);
//     const finalamount = _amounttowei;
//     console.log("finalamountStakeFix", finalamount);
//     const id = _id;
//     console.log("_idStakeFix", id);
//     const stakeAmount = await contracts.methods.stakeFix(finalamount, id).send({ from: account });
//     console.log("setStakeFix", stakeAmount);
//   }
// };

// export const getStakedIdsFixStake = async () => {
//   console.log("getStakedIdsFixStake");
//   let contracts = new web3.eth.Contract(abiEVCToken, ContractAddressTRNDToken);
//   const accounts = await window.ethereum.request({
//     method: "eth_accounts",
//   });
//   const account = accounts[0];
//   console.log("getStakedIdsFixStake_account", account);
//   const response = await contracts.methods.getStakedFixid(account).call();
//   const resCopy = [...response].sort((a, b) => a.localeCompare(b, undefined, { numeric: true }))
//   console.log("getStakedIdsFixStake", resCopy);
//   return resCopy;
// };

// export const getUserStakedFix = async () => {
//   const stakeDetail = []
//   console.log("getUserStakedFix");
//   let contracts = new web3.eth.Contract(abiEVCToken, ContractAddressTRNDToken);
//   const accounts = await window.ethereum.request({
//     method: "eth_accounts",
//   });
//   const account = accounts[0];
//   console.log("getUserStakedFix_account", account);
//   const ids = await getStakedIdsFixStake();
//   console.log("getIDS", ids);
//   for (var i = 0; i < ids.length; i++) {
//     const response = await contracts.methods.userStakedFix(account, ids[i]).call();
//     stakeDetail.push(response)
//     console.log("response", response)
//   }
//   console.log("getUserStakedFixnoloop_res", stakeDetail);
//   // const response = await contracts.methods.userStakedFix(account, ids[0]).call();
//   return stakeDetail;
// };

// export const setUnstakeFix = async (_id) => {
//   console.log("_idStakedFix", _id);
//   if (window.ethereum) {
//     const web3 = new Web3(window.ethereum);
//     await window.ethereum.request({ method: "eth_requestAccounts" });
//     // Get the selected account
//     const accounts = await window.ethereum.request({ method: "eth_accounts" });
//     const account = accounts[0];
//     const currentChainId = await web3.eth.net.getId();
//     if (currentChainId !== CurrentChainID) {
//       await web3.currentProvider.request({
//         method: "wallet_switchEthereumChain",
//         params: [{ chainId: Web3.utils.toHex(CurrentChainID) }],
//       });
//     }
//     const contracts = new web3.eth.Contract(abiEVCToken, ContractAddressTRNDToken);
//     console.log(contracts);
//     const id = _id
//     console.log("_idStakedFix", id);
//     const unstakeAmount = await contracts.methods.unstakeFix(id).send({ from: account });
//     console.log("setUntakeFix", unstakeAmount);
//   }
// };

// export const setClaimRewardFix = async (_id) => {
//   console.log("_idClaimFix", _id);
//   if (window.ethereum) {
//     const web3 = new Web3(window.ethereum);
//     await window.ethereum.request({ method: "eth_requestAccounts" });
//     // Get the selected account
//     const accounts = await window.ethereum.request({ method: "eth_accounts" });
//     const account = accounts[0];
//     const currentChainId = await web3.eth.net.getId();
//     if (currentChainId !== CurrentChainID) {
//       await web3.currentProvider.request({
//         method: "wallet_switchEthereumChain",
//         params: [{ chainId: Web3.utils.toHex(CurrentChainID) }],
//       });
//     }
//     const contracts = new web3.eth.Contract(abiEVCToken, ContractAddressTRNDToken);
//     console.log(contracts);
//     const id = _id  
//     console.log("_idClaimFix", id);
//     const unstakeAmount = await contracts.methods.claimRewardFix(id).send({ from: account });
//     console.log("setClaimRewardFix", unstakeAmount);
//   }
// };

// // function defined never used nor such view function in new contract
// export const getStakeTimeFix = async () => {
//   console.log("getStakeTimeFix");
//   let contracts = new web3.eth.Contract(abiEVCToken, ContractAddressTRNDToken);
//   const accounts = await window.ethereum.request({
//     method: "eth_accounts",
//   });
//   const account = accounts[0];
//   console.log("getStakeTimeFix_account", account);
//   const ids = await getStakedIdsFixStake();
//   const stakeTime = []
//   console.log("getIDS", ids);
//   for (var i = 0; i < ids.length; i++) {
//     const response = await contracts.methods.userStakedFix(account, ids[i]).call();
//     stakeTime.push(response.stakeTime)
//   }
//   console.log("stakeDetailresponse", stakeTime);
//   let res = stakeTime;
//   console.log("getStakeTimeFix_stakeTime", res);
//   let stakeTimeFix = res;
//   console.log("stakeTimeFix", stakeTimeFix);
//   return stakeTimeFix;
// };

// export const getApyFix = async () => {
//   console.log("getApyFix");
//   let contracts = new web3.eth.Contract(abiEVCToken, ContractAddressTRNDToken);
//   const planlength = await contracts.methods.fixPlanCount().call();
//   let planApy = [];
//   for (let i = 1; i <= planlength; i++){
//     const response = await contracts.methods.getFixApr(i).call();
//     console.log("getApyFixwithout", response);
//     const apyPrecentage = response / 100;
//     const formattedApy = apyPrecentage.toLocaleString(undefined, {maximumFractionDigits:2})
//     planApy.push(formattedApy);
// }
//   console.log("getApyFix", planApy);
//   return planApy;
// };

// Both Flex & Fix

// export const getTotalStakedEVC = async () => {
//   console.log("getTotalStakedEVC");
//   let contracts = new web3.eth.Contract(abiEVCToken, ContractAddressTRNDToken);
//   const accounts = await window.ethereum.request({
//     method: "eth_accounts",
//   });
//   const account = accounts[0];
//   console.log("getTotalStakedEVC_account", account);
//   const response = await contracts.methods.getTotalStaked().call();
//   console.log("getTotalStakedEVC",typeof response);
//   let totalstakedbalanceEVC = web3.utils.fromWei(response, "ether");
//   let totalStakedBalanceEVC = Number(parseFloat(totalstakedbalanceEVC).toFixed(2));
//   console.log("getTotalStakedEVC",typeof totalStakedBalanceEVC);
//   return totalStakedBalanceEVC;
// };